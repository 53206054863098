import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Sidebar from '../components/sidebar'
import bornetactile from "../assets/img/bornetactile.jpg"
import chargeursansfil from "../assets/img/chargeursansfil.jpg"
import lghausys from "../assets/img/lghausys.jpg"
import lghausys2 from "../assets/img/lghausys2.jpg"
import produitInterieurCollection from "../assets/img/produitInterieurCollection.jpg"
import hiMacs from "../assets/img/hiMacs.jpg"

const ActualitePage = () => (
  <Layout>
    <SEO title="Actualité" />
    <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <h1 class="text-2xl font-bold mb-8 md:mt-10 divider">Acutalité</h1>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-4 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
      <div class="grid grid-cols-1 gap-8 md:col-span-3 md:my-5 mx-3 actualite"> {/** first element */}
        <div class="grid cols-1 md:grid-cols-3 flex items-center rounded   shadow-lg my-2 hover:opacity-70 cursor-pointer">
            <img class="mb-0" src={bornetactile} alt="borne tactile" />
            <div class="md:col-span-2 px-3 md:px-6 py-4">
                <h3 class="font-bold text-xl text-center"> Borne tactile - Plan de travail tactile</h3>
                <p class="text-grey-darker text-justify text-sm">
                BORNE TACTILE – PLAN DE TRAVAIL TACTILE La TABLE TACTILE – PLAN DE TRAVAIL TACTILE est une borne interactive permettant d’afficher sur l’écran tactile les différentes applications de travail fonctionnelles avec un écran tactile. Le fonctionnement de la TABLE TACTILE – PLAN DE TRAVAIL TACTILE est très intuitif et rend le travail de groupe agréable. </p>
                <Link to={`/actualite/borne-tactile`}><span class="float-right text-white bg-red-500 p-1 rounded">Lire la suite</span></Link> 

            </div>
        </div> 
        <div class="grid cols-1 md:grid-cols-3 flex items-center rounded overflow-hidden shadow-lg my-2 hover:opacity-70 cursor-pointer">
        <img class="mb-0" src={chargeursansfil} alt="chargeur sans fil" />
        <div class="md:col-span-2 px-3 md:px-6 py-4">
          <h3 class="font-bold text-xl leading-normal text-center">Chargeur sans fil intégré dans le plan en Solid Surface</h3>
          <p class="text-grey-darker text-justify text-sm">
            <p>Le chargeur sans fil gagne de plus en plus de popularité. Nous vous avons déjà montré quelques meubles et accessoires intéressants, équipés de la technologie Qi permettant le chargement sans fil, pratique de nos smartphones et nos tablettes. D’autre côté nous avons Du Pont -la société qui possède le brevet pour </p>
            <Link to={`/actualite/chargeur-sans-fil-integre`}><span class="float-right text-white bg-red-500 p-1 rounded">Lire la suite</span></Link>
          </p>
        </div>
      </div>
      <div class="grid cols-1 md:grid-cols-3 flex items-center rounded overflow-hidden shadow-lg my-2 hover:opacity-70 cursor-pointer">
        <img class="mb-0" src={lghausys} alt="lg hausys" />
        <div class="md:col-span-2 px-3 md:px-6 py-4">
          <h3 class="font-bold text-xl leading-normal text-center"> LG Hausys dévoile, en avant première, les couleurs Velvet</h3>
          <p class="text-grey-darker text-justify text-sm">
            <p>  HI-MACS®, dévoile en avant première, les couleurs Velvet, une exclusivité de LG Hausys, disponibles le 1re mars prochain. Ces 4 couleurs inédites, Mink, Evergreen, Cosmic Blue et Suede, de la gamme Solid, trouvent leur inspiration dans la beauté de la nature et les matières organiques</p>
            <Link to={`/actualite/LG-Hausys`}><span class="float-right text-white bg-red-500 p-1 rounded">Lire la suite</span></Link>
          </p>
        </div>
      </div>
      <div class="grid cols-1 md:grid-cols-3 flex items-center rounded overflow-hidden shadow-lg my-2 hover:opacity-70 cursor-pointer">
        <img class="mb-0" src={lghausys2} alt="lg hausys" />
        <div class="md:col-span-2 px-3 md:px-6 py-4">
          <h3 class="font-bold text-xl leading-normal text-center"> LG Hausys dévoile, en avant première, les couleurs Velvet</h3>
          <p class="text-grey-darker text-justify text-sm">
            <p>  LG Hausys est de retour sur Retail Design Expo avec HI-MACS Structura® HI-MACS®, le solid surface de LG Hausys, sera présenté à l’occasion de l’édition 2017 de Retail Design Expo, les 8 &amp; 9 mai à Londres. Pouvant recouvrir n’importe quel design ou forme, extrêmement robuste, et peu exigeant en termes d’entretien</p>
            <Link to={`/actualite/lg-hausys-est-de-retour-sur-retail`}><span class="float-right text-white bg-red-500 p-1 rounded">Lire la suite</span></Link>
          </p>
        </div>
      </div>
      <div class="grid cols-1 md:grid-cols-3 flex items-center rounded overflow-hidden shadow-lg my-2 hover:opacity-70 cursor-pointer">
        <img class="mb-0" src={produitInterieurCollection} alt="Produit Interieur Collection" />
        <div class="md:col-span-2 px-3 md:px-6 py-4">
          <h3 class="font-bold text-xl leading-normal text-center"> PRODUITS D’INTÉRIEUR ET COLLECTIONS</h3>
          <p class="text-grey-darker text-justify text-sm">
            <p> PRODUITS D'INTÉRIEUR ET COLLECTIONS Quand il s’agit d’aménagement, les couleurs sont décisives. Tout est là, vous n’avez plus qu’à choisir. Téléchargez le dépliant des nouvelles couleurs Velvet ici.  </p>
            <Link to={`/actualite/produits-dinterieur-et-collections`}><span class="float-right text-white bg-red-500 p-1 rounded">Lire la suite</span></Link>
          </p>
        </div>
      </div>
      <div class="grid cols-1 md:grid-cols-3 flex items-center rounded overflow-hidden shadow-lg my-2 hover:opacity-70 cursor-pointer">
        <img class="mb-0" src={hiMacs} alt="Hi Macs" />
        <div class="md:col-span-2 px-3 md:px-6 py-4">
          <h3 class="font-bold text-xl leading-normal text-center">Qu’est-ce que HI-MACS Structura®?</h3>
          <p class="text-grey-darker text-justify text-sm">
            <p> Vous avez toujours rêvé de proposer une surface texturée, une surface reflétant des trésors de savoir-faire ? Aujourd’hui, grâce à la nouvelle technologie HI-MACS Structura®, HI-MACS® peut reproduire quasiment n’importe quel modèle – et ce avec une extrême précision. Quelque soit l’endroit où vous voulez donner  </p>
           <Link to={`/actualite/HI-MACS-Structura`}><span class="float-right text-white bg-red-500 p-1 rounded">Lire la suite</span></Link>
          </p>
        </div>
      </div>
    </div>
      <div>
        <Sidebar />
      </div>
        
    
    </div>
  
  </Layout>

  
)

export default ActualitePage